import clsx from 'clsx';

import styles from './SectionLabel.module.scss';

export const SectionLabel = ({
  text,
  center = false,
  className,
  smaller,
}: {
  text: string;
  className?: string;
  center?: boolean;
  smaller?: boolean;
}) => {
  return (
    <h2 className={clsx(styles['section-label'], center && 'center', smaller && styles['smaller'], className)}>
      {text}
    </h2>
  );
};
