import Head from 'next/head';

import { DEFAULT_META_IMAGE } from '@endaoment-frontend/constants';
import type { MetaData } from '@endaoment-frontend/types';

import { defaultPage } from '../metadata';

export const MetaTags = ({ data = defaultPage }: { data: MetaData }) => {
  return (
    <Head>
      <title>{data.title}</title>
      <meta name='description' content={data.description} />

      <meta property='og:url' content={data.url} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={data.title} />
      <meta property='og:description' content={data.description} />
      <meta property='og:image' content={data.image || DEFAULT_META_IMAGE} />

      <meta name='twitter:card' content='summary_large_image' />
      <meta property='twitter:domain' content='endaoment.org' />
      <meta property='twitter:url' content={data.url} />
      <meta name='twitter:title' content={data.title} />
      <meta name='twitter:description' content={data.description} />
      <meta name='twitter:image' content={data.image || DEFAULT_META_IMAGE} />
    </Head>
  );
};
